<template>
<div class="page-container">
    <div class="page-top">
        <el-form :inline="true" :model="formData">
            <el-form-item label="客户：">
                <el-select v-model="formData.CustomerId" placeholder="请选择...">
                    <el-option
                        v-for="item in companies"
                        :key="item.CustomerID"
                        :label="item.CompanyName"
                        :value="item.CustomerID" 
                    />
                </el-select>
            </el-form-item>
            <el-form-item label="QQ号：">
                <el-input v-model="formData.BotUid" @keyup.enter.native="handleSearch" clearable placeholder="请输入..."></el-input>
            </el-form-item>
            <el-form-item label="QQ号(发送)：">
                <el-input v-model="formData.SenderUid" @keyup.enter.native="handleSearch" clearable placeholder="请输入..."></el-input>
            </el-form-item>
            <el-form-item label="内容：">
                <el-input v-model="formData.Keyword" @keyup.enter.native="handleSearch" clearable placeholder="请输入..."></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
            </el-form-item>
        </el-form>
    </div>
    <div class="page-table">
        <el-table
            stripe
            border
            row-key="Id"
            :data="tableData.items"
            :height="tableData.height"
            v-loading="tableData.loading"
            :header-cell-style="{background:'#eef1f6',color:'#606266'}"
        >
            <el-table-column prop="CustomerName" label="客户" width="120"/>
            <el-table-column prop="BotUid" label="QQ/微信" width="120"/>
            <el-table-column prop="BotName" label="QQ/微信昵称" width="120"/>
            <el-table-column prop="SenderUId" label="发送人QQ" width="120"/>
            <el-table-column prop="SenderName" label="发送人昵称" width="120"/>
            <el-table-column prop="Content" label="内容">
                <template slot-scope="{row}">
                    {{row.Content}}<el-button type="primary" plain @click="e=>handleCopy(row.Content, e)" class="copy-content">复制</el-button>
                </template>
            </el-table-column>
            <el-table-column prop="Remark" label="备注" width="150"/>
        </el-table>
        <el-pagination
            background
            :total="tableData.total"
            :page-size="tableData.pageSize"
            :current-page="tableData.currentPage"
            :page-sizes="[10, 15, 20, 30, 50]"
            layout="prev, pager, next,total, sizes"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        />
    </div>
</div>
</template>
<script>
import { submit } from "@/api/base";
import clipboard from "@/utils/clipboard";

export default {
    name:'StallMessage',
    data() {
        return {
            loading: false,
            companies:[],
            formData:{
                CustomerId:'',
                BotUid:'',
                SenderUid:'',
                Keyword:''
            },
            tableData:{
                loading:false,
                items:[],
                total:0,
                pageSize:15,
                currentPage:1,
            },
        }
    },
    mounted() {
        const _this = this;
        _this.$nextTick(async () => {
            await _this.fetchData();
            
            const { data } = await submit("/api/stall/GetCompanies");
            _this.companies = data;
        });
    },
    methods:{
        async fetchData(){
            const _this = this;

            _this.tableData.loading = true;
            const { data } = await submit("/api/stall/getList",{
                PageSize:_this.tableData.pageSize,
                CurrentPage:_this.tableData.currentPage,
                CustomerId:_this.formData.CustomerId,
                BotUid:_this.formData.BotUid,
                SenderUid:_this.formData.SenderUid,
                Keyword:_this.formData.Keyword,
            }).finally(()=>{
                _this.tableData.loading = false;
            });

            _this.tableData.total = data.total;
            _this.tableData.items = data.datas;
        },
        handleSearch(){
            this.fetchData();
        },
        handleSizeChange(size){
            this.tableData.pageSize = size;
            this.fetchData();
        },
        handleCurrentChange(page){
            this.tableData.currentPage = page;
            this.fetchData();
        },
        handleCopy(content, e) {
            clipboard(content, e);
        }
    }
}
</script>
<style lang="scss" scoped>
.page-top {
  padding: 6px 14px 0 14px;
}
.page-table{
    text-align: right;
    padding: 0 14px 0 14px;
}
.copy-content {
    margin-left: 10px;
    padding: 5px 10px !important;
}
</style>